import React from "react";
export const footnoteText = (footnoteIndex) => {
    let index = Math.trunc(footnoteIndex);
    if (index == 0) {
        return "⁰";
    }
    let result = "";
    while (index > 0) {
        let remainder = index % 10;
        index = Math.floor(index / 10);
        let newChar;
        switch (remainder) {
            case 1:
                newChar = "¹";
                break;
            case 2:
                newChar = "²";
                break;
            case 3:
                newChar = "³";
                break;
            default:
                newChar = String.fromCodePoint(0x2070 + remainder);
        }
        result = newChar + result;
    }
    return result;
};
export class Footnote extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const footnoteNumber = this.props.footnoteNumber ?? 0;
        return React.createElement(React.Fragment, null,
            React.createElement("a", { id: "footnote_orig_" + footnoteNumber }),
            React.createElement("a", { href: "#footnote_dest_" + footnoteNumber, className: "footnoteLink" }, footnoteText(footnoteNumber)));
    }
}
class FootnoteProcessor {
    constructor(children) {
        this.counter = 1;
        this.footnotes = [];
        this.processChild = (child) => {
            // @ts-ignore
            const children = child.props ? child.props.children : undefined;
            // @ts-ignore
            const isFootnote = child.type === Footnote || (child.props && child.props.mdxType === "Footnote");
            if (children || isFootnote) {
                let newChildren = children ? React.Children.map(children, this.processChild) : children;
                let footnoteProps = {};
                if (isFootnote) {
                    const footnoteNumber = this.counter++;
                    const footnoteChildren = newChildren;
                    footnoteProps = { footnoteNumber: footnoteNumber };
                    this.footnotes.push(React.createElement("div", { className: "footnote", key: footnoteNumber },
                        React.createElement("a", { id: "footnote_dest_" + footnoteNumber }),
                        React.createElement("a", { href: "#footnote_orig_" + footnoteNumber, className: "footnoteLink", style: { marginRight: "10px" } }, footnoteText(footnoteNumber)),
                        footnoteChildren));
                }
                //<p><a name="footnote_dest_{{$n}}"></a><a href="#footnote_orig_{{$n}}" class="footnote" style="margin-right: 10px;">{{- $f -}}</a>{{ $elem_val | markdownify }}</p>
                // @ts-ignore
                return React.cloneElement(child, footnoteProps, newChildren);
            }
            return child;
        };
        this.children = children;
    }
    process() {
        return React.Children.map(this.children, child => (this.processChild(child)));
    }
}
export class Footnotes extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const ruleStyle = {
            width: "200px",
            border: "none",
            borderTop: "1px solid",
            marginLeft: "0px",
            marginTop: "50px",
        };
        const processor = new FootnoteProcessor(this.props.children);
        const children = processor.process();
        const footnotes = processor.footnotes.length > 0
            ? React.createElement(React.Fragment, null,
                React.createElement("hr", { style: ruleStyle }),
                processor.footnotes)
            : null;
        return React.createElement(React.Fragment, null,
            children,
            footnotes);
    }
}
