import React from "react";
import { Layout } from "../components/layout";
import { MDXProvider } from "@mdx-js/react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Footnote, Footnotes } from "../components/footnote";
import Img from "gatsby-image";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
const codeComponent = ({ children, className }) => {
    const language = className.replace(/language-/, '');
    return (React.createElement(SyntaxHighlighter, { language: language, style: prism, showLineNumbers: true, lineNumberContainerProps: {
            style: {
                textAlign: "right",
                float: "left",
                paddingRight: "10px",
                color: "#7f7f7f"
            }
        } }, children));
};
export const safeFluid = (f) => {
    return {
        aspectRatio: f ? f.aspectRatio || 1 : 1,
        sizes: f ? f.sizes || '' : '',
        src: f ? f.src || '' : '',
        srcSet: f ? f.srcSet || '' : '',
        tracedSVG: f ? f.tracedSVG || '' : ''
    };
};
export const MdxSharpImg = ({ fluid }) => (React.createElement("span", { style: { width: "100%" } },
    React.createElement(Img, { fluid: fluid })));
export const MdxSrcImg = ({ src }) => (React.createElement("img", { style: { width: "100%" }, src: src }));
export const safe = (obj) => {
    return obj ? obj : {};
};
export const Table = ({ children }) => (React.createElement("table", { className: "sql-table" }, children));
export const Row = ({ children }) => (React.createElement("tr", null, children));
export const Cell = ({ children, fixedStyle }) => {
    const styledChidren = fixedStyle === "code" ? React.createElement("code", null, children) : children;
    return React.createElement("td", null, styledChidren);
};
const providerComponents = {
    Footnote: Footnote,
    Table: Table,
    Row: Row,
    Cell: Cell,
    code: codeComponent,
};
const rendererComponents = {
    wrapper: Footnotes
};
export const Blog = ({ data }) => {
    const images = data.mdx?.frontmatter?.images;
    const imgs = {};
    if (images) {
        images.forEach((image, i) => {
            const { childImageSharp: c, publicURL } = safe(image);
            const { fluid: f } = safe(c);
            imgs[image?.name || ''] = () => f ? (React.createElement(MdxSharpImg, { fluid: safeFluid(f) })) : (React.createElement(MdxSrcImg, { src: publicURL || '' }));
        });
    }
    return (React.createElement(Layout, null,
        React.createElement("div", { className: "content" },
            React.createElement("p", null,
                React.createElement("em", null,
                    "by ",
                    data.mdx?.frontmatter?.author)),
            React.createElement("h1", null, data.mdx?.frontmatter?.title),
            React.createElement(MDXProvider, { components: providerComponents },
                React.createElement(MDXRenderer, { imgs: imgs, components: rendererComponents }, data.mdx?.body ?? "")))));
};
export const query = graphql `
    query BlogSinglePostMarkdown ($slug: String!)  {
        mdx(fields: { slug: { eq: $slug } }) {
            body
            frontmatter {
                author
                title
                images {
                    name
                    publicURL
                    childImageSharp {
                        fluid {
                            base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;
export default Blog;
